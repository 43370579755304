import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'
import socketIO from 'socket.io-client'

let _socket

export const socketConnectionFn = (protocol, url, id, lang, eventCallbacks) => {
  _socket = socketIO(`${protocol}://${url}/im/user?id=${id}&lang=${lang}}`, {
    transports: ['websocket', 'polling']
  })

  // 連線成功
  _socket.on('connect', () => {
    if (eventCallbacks.connectStatus) {
      eventCallbacks.connectStatus(true)
    }
  })

  // user_event
  _socket.on('user_event', (info) => {
    if (eventCallbacks.userEvent) {
      eventCallbacks.userEvent(info)
    }
  })

  // 分配事件
  _socket.on('assigned', (info) => {
    if (eventCallbacks.assigned) {
      eventCallbacks.assigned(info)
    }
  })

  // 訊息
  _socket.on('message', (info) => {
    if (eventCallbacks.message) {
      eventCallbacks.message(info)
    }
  })

  // 結束會話
  _socket.on('finished', (info) => {
    if (eventCallbacks.finished) {
      eventCallbacks.finished(info)
    }
  })

  // 邀請評價
  _socket.on('invite_rating', (info) => {
    if (eventCallbacks.inviteRating) {
      eventCallbacks.inviteRating(info)
    }
  })

  // 對話驗證
  _socket.on('anti_verification', (info) => {
    if (eventCallbacks.antiVerification) {
      eventCallbacks.antiVerification(info)
    }
  })

  // 詢前表單
  _socket.on('info_collection', (info) => {
    if (eventCallbacks.infoCollection) {
      eventCallbacks.infoCollection(info)
    }
  })

  // error
  _socket.on('error', (info) => {
    if (eventCallbacks.error) {
      eventCallbacks.error(info)
    }
  })

  // 斷開連線
  _socket.on('disconnect', () => {
    if (eventCallbacks.connectStatus) {
      eventCallbacks.connectStatus(false)
    }
  })
}

// 斷開連線
export const socketDisconnectFn = () => {
  if (_socket) {
    _socket.disconnect()
  }
}

// 發送 newConversation
export const newConversationFn = (requestId, secret, reqConversation, infoCollection, antiVerificationToken) => {
  const signature = generateSignature(secret, reqConversation)
  const requestData = buildRequestData(
    requestId,
    signature,
    { reqConversation },
    infoCollection ? { infoCollection } : {},
    antiVerificationToken ? { antiVerificationToken } : {}
  )

  if (_socket) {
    _socket.emit('newConversation', requestData)
  }
}

// 發送 message
export const sendMessageFn = (secret, message) => {
  const signature = generateSignature(secret, message)
  const requestData = buildRequestData(null, signature, { message })

  if (_socket) {
    _socket.emit('message', requestData)
  }
}

// 發送 typing
export const sendTypingFn = (secret, message) => {
  const signature = generateSignature(secret, message)
  const requestData = buildRequestData(null, signature, { message })

  if (_socket) {
    _socket.emit('typing', requestData)
  }
}

// 發送 readMsg 消息已讀
export const readMsgFn = (info) => {
  if (_socket) {
    _socket.emit('readMsg', info)
  }
}

// 發送 delivered 消息抵達
export const MsgDeliveredFn = (info) => {
  if (_socket) {
    _socket.emit('delivered', info)
  }
}

/**
 * 建構數據
 * @param {*} secret
 * @param {*} type
 * @param {*} data
 * @returns
 */
export const buildRequestData = (requestId, signature, ...payloads) => {
  return {
    requestId: requestId || uuidv4(),
    signature,
    ...payloads.reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }
}

/**
 * 生成 SHA256 加密的签名
 * @param {string} secret - 密钥
 * @param {Object} data - 请求的具体数据 (如 message 或 reqConversation)
 * @returns {string} - 生成的 SHA256 签名
 */
export const generateSignature = (secret, data) => {
  const timestamp = Math.floor(Date.now() / 1000) // 当前时间戳（秒）
  const dataString = JSON.stringify(data) // 将数据转换为字符串
  const rawSignature = dataString + timestamp + secret // 拼接 key、时间戳和数据
  const hash = CryptoJS.SHA256(rawSignature).toString() // 生成 SHA256 hash
  return hash
}
