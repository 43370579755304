<template>
  <div id="chat">
    <!-- 分配成功 -->

    <template v-if="baseInfo.assignedStatus == 'success'">
      <LazyChatInChat
        ref="chatComponentRef"
        :chat-info="chatInfo"
        :is-finished-con="isFinishedCon"
      />
      <!-- 邀請評價 -->
      <template v-if="baseInfo.tenantInfo.satisfactionRatingEnabled && !baseInfo.channelInfo.visitorRatingEnabled">
        <LazyChatInviteRating
          :is-show="inviteRatingDisplay"
          :rating-info="ratingInfo"
          :rating-prompt="baseInfo.tenantInfo.satisfactionRatingPrompt"
        />
      </template>
    </template>
    <!-- 防刷驗證 -->
    <template v-else-if="baseInfo.assignedStatus == 'anti_verification'">
      <LazyChatInChatVerification :is-show="verificationDisplay" />
      <LazyChatVerificationDlg
        :is-show="verificationDisplay"
        :user-id="userId"
        :verification-info="verificationInfo"
        @closeVerificationDlg="handleVerificationSubmit"
      />
    </template>
    <!-- 信息收集 -->
    <template v-else-if="baseInfo.assignedStatus == 'info_collection'">
      <LazyChatInfoCollection
        :info-collection-form="infoCollectionFields"
        :info-pre-text="infoPreText"
        @info-collection-submit-form="handleInfoCollectionSubmit"
      />
    </template>
    <!-- 進留言 -->
    <template v-else-if="baseInfo.assignedStatus == 'in_feedback'">
      <LazyChatInFeedback
        :in-feedback-form-info="baseInfo.channelInfo"
        :customer-id="baseInfo.userInfo.userId"
      />
    </template>
    <!-- 進排隊 -->
    <template v-else-if="baseInfo.assignedStatus == 'in_queue'">
      <LazyChatInQueue
        ref="queueComponentRef"
        :queue-info="queueInfo"
      />
    </template>
    <template v-else-if="chatInfo.assignedStatus == 'failed'">
      <LazyChatInFeedbackFailed />
    </template>
  </div>
</template>

<script setup>

import { v4 as uuidv4 } from 'uuid'
import { socketConnectionFn, socketDisconnectFn, newConversationFn, sendMessageFn, readMsgFn, MsgDeliveredFn } from '../../composables/socketEvents'

const baseInfo = ref({
  assignedStatus: '', // 分配狀態
  userInfo: {}, // 訪客資訊
  channelInfo: {}, // 渠道資訊
  tenantInfo: {} // 租戶資訊
})

const chatInfo = ref({
  agentInfo: {}, // 客服資訊
  conversationInfo: {}, // 會話資訊
  commonProblemList: [], // 常見問題
  ossDomain: 'https://oss.e2zfz2.com' // http://172.16.124.185:9000
})

const queueInfo = ref({
  commonProblemList: [], // 常見問題
  queueCount: 0, // 排隊數
  queueTimeout: false // 排隊超時
})

// 發起會話唯一標示 接收assigned事件要驗證跟requestId是否一致
const newConvRequestId = ref('')

// 訪客Id
const userId = ref('')

// 引用子組件
const chatComponentRef = ref(null)
const queueComponentRef = ref(null)

onBeforeMount(() => {
  userId.value = localStorage.getItem('userId') || ''
  newConFn()
  localStorage.setItem('sucRating', false)
})

// url
const url = useRoute()
const channelUrl = url.params.channelUrl // 取得channelUrl
const agentIdFromUrl = url.query.userId // 取得客服id
const departmentIdFromUrl = url.query.deptId // 取得部門id
const langUrl = url.query.lang // 取得語系

// reqConversation Obj (此順序不能改)
const reqConversation = {
  agentId: agentIdFromUrl,
  channelUrl,
  departmentId: departmentIdFromUrl
}

// 建立連線 發起會話
const newConFn = () => {
  establishConnection()
}

// SOCKET 連線狀態
const socketConnectStatus = ref(false)

// SOCKET 事件 ---
const establishConnection = () => { // 建立連線
  // TODO 配置
  const protocol = 'wss'
  const url = '500ef3.com'
  // TODO 從網址拿 or 瀏覽器語言
  const lang = langUrl

  socketConnectionFn(protocol, url, userId.value, lang, {
    connectStatus: handleConnectStatus,
    userEvent: handleUserEvent,
    assigned: handleAssigned,
    message: handleMessage,
    finished: handleFinished,
    inviteRating: handleInviteRating,
    antiVerification: handleAntiVerification,
    infoCollection: handleInfoCollection,
    error: handleError
  })
}

// 連線
const handleConnectStatus = (data) => {
  // data = true 代表連線
  // data = false 代表斷線

  // TODO  斷線行為、連線行為、斷線嘗試重連

  socketConnectStatus.value = data
}

// user_event
const handleUserEvent = (data) => {
  baseInfo.value.userInfo = data
  userId.value = data.userId
  localStorage.setItem('userId', userId.value)

  newConvRequestId.value = uuidv4()
  newConversationFn(newConvRequestId.value, baseInfo.value.userInfo.secret, reqConversation)
}

// 分配事件
const handleAssigned = (data) => {
  if (data.requestId !== newConvRequestId.value) {
    return
  }

  const status = data.status
  if (status === 'success') { // 進線
    assignedSuccessFn(data)
  } else if (status === 'in_feedback') { // 留言
    inFeedbackFn(data)
  } else if (status === 'in_queue') { // 排隊
    inQueueFn(data)
  } else if (status === 'failed') { // 錯誤
    baseInfo.value.assignedStatus = 'failed'
  } else {
    console.log('error')
  }
}

// 接收訊息
const handleMessage = (data) => {
  const newMsg = data.message
  if (newMsg.conversationId !== chatInfo.value.conversationInfo.id) {
    return
  }

  // 排隊超時(文字)
  if (newMsg.msgType === 'queue_timeout_prompt') {
    queueInfo.value.queueTimeout = true
  } else {
    nextTick(() => {
      if (chatComponentRef.value) {
        chatComponentRef.value.processMsg(newMsg)
        chatComponentRef.value.pushMessage(newMsg)
      }
    })
  }
}

// 會話結束
const handleFinished = (data) => {
  if (data.conversationId !== chatInfo.value.conversationInfo.id) {
    return
  }

  if (data.status === 'success') {
    // 排隊超時斷開連線
    if (data.endReason === 'queue_timeout') {
      queueInfo.value.queueTimeout = true
    } else {
      isShowFinishedBlock(true)

      // 判斷是否要填【滿意度評價】
      const isShowRatingFrom = localStorage.getItem('sucRating')
      if (baseInfo.value.tenantInfo.satisfactionRatingEnabled &&
          !baseInfo.value.channelInfo.visitorRatingEnabled &&
          isShowRatingFrom === 'false') {
        inviteRatingFn()
      }
    }

    // socket斷線
    socketDisconnectFn()
  }
}

// 邀請評價
const handleInviteRating = (data) => {
  if (data.conversationId !== chatInfo.value.conversationInfo.id) {
    return
  }

  inviteRatingFn()
}

// 對話驗證
const verificationDisplay = ref(false)
const verificationInfo = ref({})

const handleAntiVerification = (data) => {
  if (data) {
    setTimeout(() => {
      verificationDisplay.value = true
      verificationInfo.value = data
    }, 300)
  }

  baseInfo.value.assignedStatus = 'anti_verification'
}

// 信息收集
const infoCollectionFields = ref([])
const infoPreText = ref('')

const handleInfoCollection = (data) => {
  infoCollectionFields.value = data.data.infoCollection
  infoPreText.value = data.data.infoPreText
  baseInfo.value.assignedStatus = 'info_collection'
}

// error事件
const handleError = (data) => {
  const errorMsg = data.msg
  ElMessage.error({ message: errorMsg })
  console.error('Error:', data)
}

// 分配成功 ---
const assignedSuccessFn = (assigned) => {
  Object.assign(baseInfo.value, {
    assignedStatus: 'success',
    channelInfo: assigned.channel,
    tenantInfo: assigned.tenant
  })

  Object.assign(chatInfo.value, {
    agentInfo: assigned.agent,
    conversationInfo: assigned.conversation,
    commonProblemList: assigned.commonProblem
  })

  isShowFinishedBlock(false)
}

// 排隊 ---
const inQueueFn = (assigned) => {
  Object.assign(baseInfo.value, {
    assignedStatus: 'in_queue',
    channelInfo: assigned.channel,
    tenantInfo: assigned.tenant
  })

  Object.assign(queueInfo.value, {
    commonProblemList: assigned.commonProblem,
    queueCount: assigned.queueCount
  })
}

// 留言 ---
const inFeedbackFn = (assigned) => {
  Object.assign(baseInfo.value, {
    assignedStatus: 'in_feedback',
    channelInfo: assigned.channel
  })

  // socket斷線
  socketDisconnectFn()
}

// 提交信息收集表單
const handleInfoCollectionSubmit = (fromData) => {
  newConvRequestId.value = uuidv4()
  newConversationFn(newConvRequestId.value, baseInfo.value.userInfo.secret, reqConversation, fromData)
}

// 防刷驗證
const handleVerificationSubmit = (antiVerificationToken) => {
  if (antiVerificationToken) {
    newConvRequestId.value = uuidv4()
    newConversationFn(newConvRequestId.value, baseInfo.value.userInfo.secret, reqConversation, null, antiVerificationToken)
  }
  verificationDisplay.value = false
}

// 發送對話 ----
const sendMsgFn = (msg, msgType) => {
  const message = {
    channelId: chatInfo.value.conversationInfo.channelId,
    content: msg,
    conversationId: chatInfo.value.conversationInfo.id,
    msgType: msgType || 'text',
    tenantId: chatInfo.value.conversationInfo.tenantId
  } // 此順序不能改

  sendMessageFn(baseInfo.value.userInfo.secret, message)
}

// 發送常見問題 ----
const sendCommonProblem = (item) => {
  if (baseInfo.value.assignedStatus === 'success') {
    sendMsgFn(item.name, 'common_problem')
  } else if (baseInfo.value.assignedStatus === 'in_queue') {
    queueComponentRef.value.sendCommonProblemInQueue(item)
  }
}

// 底部輸入框 ----
const isFinishedCon = ref(false)
const isShowFinishedBlock = (value) => {
  isFinishedCon.value = value
}

// 邀請評價
const inviteRatingDisplay = ref(false)
const ratingInfo = ref()
const inviteRatingFn = () => {
  ratingInfo.value = {
    customerId: baseInfo.value.userInfo.userId,
    conId: chatInfo.value.conversationInfo.id,
    tenantId: chatInfo.value.conversationInfo.tenantId
  }

  inviteRatingDisplay.value = true
}
const closeRatingDlg = () => {
  inviteRatingDisplay.value = false
}

provide('chatProvide', {
  baseInfo,
  closeRatingDlg,
  sendMsgFn, // 發送對話
  newConFn, // 繼續對話
  sendCommonProblem // 發送常見問題
})
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/chat.scss";

#chat {
  height: 100vh;

  .statusSuccess { // 分配成功
    display: flex; justify-content: space-between;

    :deep(.v-btn) {
      min-width:20px;
    }
    :deep(.v-field__outline) {
      display: none;
    }
  }
}
</style>
